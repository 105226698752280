window.addEventListener('load', function () {

	console.log('loading share');

	const shareButton = document.getElementById('share-button');
	if (!shareButton) {
		console.log("Can't find share button");
		return;
	}
	console.log(shareButton);

	const shareModal = document.getElementById('share-modal');
	if (!shareModal) {
		console.log("Can't find share modal");
		return;
	}
	console.log(shareModal);

	shareButton.addEventListener('click', () => shareModal.classList.toggle('visible'));

	window.addEventListener('click', function (evt) {
		if (evt.target == shareModal) {
			shareModal.classList.remove('visible');
		}
	});

});
